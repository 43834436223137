import type { FlatButtonProps } from '../Types'
import { cloneElement, useMemo } from 'react'
import classNames from 'classnames'
import TypedButtonWrapper from '../TypedButtonWrapper'
import isPresent from '@vayapin/utils/isPresent'

const DEFAULT_CLASSES = `
  relative inline-block h-16 overflow-hidden
  rounded-2xl border-none outline-0 pointer-events-auto no-underline
  flex flex-row items-center justify-start gap-2
  my-2 py-3 px-5
`

const NOT_DISABLED_CLASSES = `
  bg-neutral-700/10 hover:bg-neutral-700/20
  text-neutral-600
  hover:cursor-pointer hover:text-neutral-600
`

const DISABLED_CLASSES = `
  bg-neutral-700/10
  text-neutral-400
  opacity-60 hover:cursor-not-allowed
`

function FlatButton({
  type = 'default',
  href,
  className,
  disabled,
  block,
  iconLeft,
  children,
  ...restProps
}: Readonly<FlatButtonProps>) {
  const secureType = useMemo(() => isPresent(href) ? 'link' : type, [type, href])

  const classes = useMemo(() => classNames({
    [DEFAULT_CLASSES]: true,
    [NOT_DISABLED_CLASSES]: !disabled,
    [DISABLED_CLASSES]: disabled,
    ['w-full text-left']: block,
    [className ?? '']: isPresent(className),
  }), [block, className, disabled])

  const iconClasses = useMemo(() => classNames({
    'w-6 h-6': true,
    'text-neutral-600': !disabled,
    'text-neutral-400 group-hover/vp-flat-btn:text-neutral-400': disabled,
  }), [disabled])

  const adjustedIcon = useMemo(() => iconLeft ? cloneElement(iconLeft, {
    className: iconClasses,
  }) : undefined, [iconLeft, iconClasses])

  return (
    <TypedButtonWrapper
      disabled={disabled}
      type={secureType}
      className={classes}
      href={href}
      {...restProps}
    >
      {!!iconLeft && (
        <div>
          {adjustedIcon}
        </div>
      )}
      {children}
    </TypedButtonWrapper>
  )
}

export default FlatButton

import type { CountryFlagProps } from './Types'
import { useMemo, memo, cloneElement } from 'react'
import classNames from 'classnames'
import iconsMap from '@vayapin/svg-country-flags/iconsMap'
import isValidNumber from '@vayapin/utils/isValidNumber'

/**
 * Renders a country flag icon
 */
function CountryFlag({
  countryCode,
  width,
  height,
  fallbackIcon,
  className
}: Readonly<CountryFlagProps>) {
  const country = useMemo(() => countryCode?.toLowerCase(), [countryCode])
  const w = useMemo(() => isValidNumber(width) || isValidNumber(height) ? width : 32, [height, width])

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
  const Icon = useMemo(() => iconsMap[country], [country])

  const iconClasses = useMemo(() => classNames({
    [width && width < 32 ? 'rounded-sm' : 'rounded-md']: true,
    [className ?? '']: true,
  }), [className, width])

  const adjustedFallbackIcon = useMemo(() => {
    if (!fallbackIcon) return null

    return cloneElement(fallbackIcon, { width: w ? w - 8 : 24, className })
  }, [className, fallbackIcon, w])

  if (Icon) {
    return (
      <Icon className={iconClasses} width={w} height={height} />
    )
  }

  if (adjustedFallbackIcon) {
    return adjustedFallbackIcon
  }

  return null
}

export default memo(CountryFlag)

import type { CircleButtonProps } from '../Types'
import { useMemo } from 'react'
import classNames from 'classnames'
import isPresent from '@vayapin/utils/isPresent'

export interface TitleProps {
  title: CircleButtonProps['title'];
  disabled: CircleButtonProps['disabled'];
}

const DEFAULT_CLASSES = `
  block text-center text-neutral-800
`

const DISABLED_CLASSES = `
  block text-center text-neutral-400 group-hover/vp-circle-btn:text-neutral-400
`

function Title({ title, disabled }: Readonly<TitleProps>) {
  const hasTitle = useMemo(() => isPresent(title), [title])

  const labelClasses = useMemo(() => classNames({
    [DEFAULT_CLASSES]: !disabled,
    [DISABLED_CLASSES]: disabled,
  }), [disabled])

  return hasTitle ? (
    <span className={labelClasses}>
      {title}
    </span>
  ) : null
}

export default Title

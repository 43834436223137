import { LockClosed, LockUnlock } from '@vayapin/svg-icons/svg-react'

export interface LockIconProps {
  unlocked?: boolean;
}

function LockIcon({ unlocked = false }: LockIconProps) {
  return (
    <div className={`
      absolute rounded-full top-9 right-0 z-10 p-0.5
      flex items-end justify-end bg-white shadow-xl
    `}>
      {unlocked ? (
        <LockUnlock className='text-primary-400 w-4 h-4' />
      ) : (
        <LockClosed className='text-secondary-600 w-4 h-4' />
      )}
    </div>
  )
}

export default LockIcon

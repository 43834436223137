import type { SquareButtonProps } from '../Types'
import { useMemo } from 'react'
import classNames from 'classnames'
import TypedButtonWrapper from '../TypedButtonWrapper'
import isPresent from '@vayapin/utils/isPresent'
import Square from './Square'
import Title from './Title'

const DEFAULT_CLASSES = `
  group/vp-square-btn
  relative border-none outline-0 pointer-events-auto no-underline
  flex flex-col items-center justify-center gap-2
  max-w-16 my-2 p-2 rounded-md
`

const NOT_DISABLED_CLASSES = `
  hover:cursor-pointer
`

const DISABLED_CLASSES = `
  opacity-60
  hover:bg-transparent
  hover:cursor-not-allowed
`

function SquareButton({
  type = 'default',
  theme = 'default',
  href,
  className,
  labelClass,
  disabled,
  icon,
  title,
  iconBlurred,
  children,
  ...restProps
}: Readonly<SquareButtonProps>) {
  const secureType = useMemo(() => isPresent(href) ? 'link' : type, [type, href])

  const classes = useMemo(() => classNames({
    [DEFAULT_CLASSES]: true,
    [NOT_DISABLED_CLASSES]: !disabled,
    [DISABLED_CLASSES]: disabled,
    [className ?? '']: isPresent(className),
  }), [className, disabled])

  const squareProps = useMemo(() => ({
    theme,
    icon,
    disabled,
    blurred: iconBlurred,
  }), [theme, icon, disabled, iconBlurred])

  return (
    <TypedButtonWrapper
      disabled={disabled}
      type={secureType}
      className={classes}
      href={href}
      {...restProps}
    >
      {theme === 'default' && (
        <Square.Default
          {...squareProps}
          iconClassName='group-hover/vp-square-btn:text-primary-400'
        />
      )}
      {theme === 'primary' && (
        <Square.Primary
          {...squareProps}
          className='group-hover/vp-square-btn:bg-primary-300 group-hover/vp-square-btn:border-primary-300'
        />
      )}
      {theme === 'secondary' && (
        <Square.Secondary
          {...squareProps}
          className='group-hover/vp-square-btn:bg-lavender-300 group-hover/vp-square-btn:border-lavender-300'
        />
      )}
      <Title title={title} disabled={disabled} theme={theme} className={labelClass} />
      {children}
    </TypedButtonWrapper>
  )
}

export default SquareButton

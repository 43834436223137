import type { CircleButtonProps } from '../Types'
import { useMemo } from 'react'
import classNames from 'classnames'
import TypedButtonWrapper from '../TypedButtonWrapper'
// @ts-ignore
import Styles from './CircleButton.module.sass'
import isPresent from '@vayapin/utils/isPresent'
import Circle from './Circle'
import Title from './Title'

const CIRCLE_BUTTON_DEFAULT_CLASSES = [
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  Styles.button,
  'group/vp-circle-btn inline-block',
  'relative border-none outline-0 pointer-events-auto no-underline',
  'flex flex-col items-center justify-center gap-2',
  'my-2',
  'hover:cursor-pointer text-white',
].join(' ')

const CIRCLE_BUTTON_DISABLED_CLASSES = [
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  Styles.buttonDisabled,
  'hover:cursor-not-allowed',
].join(' ')

function CircleButton({
  type = 'default',
  href,
  className,
  disabled,
  icon,
  title,
  theme = 'default',
  ...restProps
}: Readonly<CircleButtonProps>) {
  const secureType = useMemo(() => isPresent(href) ? 'link' : type, [type, href])

  const classes = useMemo(() => classNames({
    [CIRCLE_BUTTON_DEFAULT_CLASSES]: true,
    [CIRCLE_BUTTON_DISABLED_CLASSES]: disabled,
    [className ?? '']: isPresent(className),
  }), [className, disabled])

  return (
    <TypedButtonWrapper
      disabled={disabled}
      type={secureType}
      className={classes}
      href={href}
      {...restProps}
    >
      <Circle disabled={disabled} theme={theme} icon={icon} />
      <Title title={title} disabled={disabled} />
    </TypedButtonWrapper>
  )
}

export default CircleButton

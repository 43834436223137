import EaIcon from './svg-react/Ea'
import BmIcon from './svg-react/Bm'
import ZmIcon from './svg-react/Zm'
import ViIcon from './svg-react/Vi'
import VaIcon from './svg-react/Va'
import UzIcon from './svg-react/Uz'
import UyIcon from './svg-react/Uy'
import TjIcon from './svg-react/Tj'
import TcIcon from './svg-react/Tc'
import SvIcon from './svg-react/Sv'
import RwIcon from './svg-react/Rw'
import RsIcon from './svg-react/Rs'
import PtIcon from './svg-react/Pt'
import PnIcon from './svg-react/Pn'
import PfIcon from './svg-react/Pf'
import NiIcon from './svg-react/Ni'
import NfIcon from './svg-react/Nf'
import MpIcon from './svg-react/Mp'
import MdIcon from './svg-react/Md'
import LkIcon from './svg-react/Lk'
import LiIcon from './svg-react/Li'
import KrIcon from './svg-react/Kr'
import KhIcon from './svg-react/Kh'
import IoIcon from './svg-react/Io'
import InIcon from './svg-react/In'
import HtIcon from './svg-react/Ht'
import GsIcon from './svg-react/Gs'
import GdIcon from './svg-react/Gd'
import GbWlsIcon from './svg-react/GbWls'
import GbNirIcon from './svg-react/GbNir'
import FjIcon from './svg-react/Fj'
import EsGaIcon from './svg-react/EsGa'
import EsIcon from './svg-react/Es'
import DgIcon from './svg-react/Dg'
import CxIcon from './svg-react/Cx'
import CmIcon from './svg-react/Cm'
import BzIcon from './svg-react/Bz'
import BtIcon from './svg-react/Bt'
import BoIcon from './svg-react/Bo'
import ArIcon from './svg-react/Ar'
import ZwIcon from './svg-react/Zw'
import ZaIcon from './svg-react/Za'
import YtIcon from './svg-react/Yt'
import YeIcon from './svg-react/Ye'
import XxIcon from './svg-react/Xx'
import XkIcon from './svg-react/Xk'
import WsIcon from './svg-react/Ws'
import WfIcon from './svg-react/Wf'
import VuIcon from './svg-react/Vu'
import VnIcon from './svg-react/Vn'
import VgIcon from './svg-react/Vg'
import VeIcon from './svg-react/Ve'
import VcIcon from './svg-react/Vc'
import UsIcon from './svg-react/Us'
import UnIcon from './svg-react/Un'
import UmIcon from './svg-react/Um'
import UgIcon from './svg-react/Ug'
import UaIcon from './svg-react/Ua'
import TzIcon from './svg-react/Tz'
import TwIcon from './svg-react/Tw'
import TvIcon from './svg-react/Tv'
import TtIcon from './svg-react/Tt'
import TrIcon from './svg-react/Tr'
import ToIcon from './svg-react/To'
import TnIcon from './svg-react/Tn'
import TmIcon from './svg-react/Tm'
import TlIcon from './svg-react/Tl'
import TkIcon from './svg-react/Tk'
import ThIcon from './svg-react/Th'
import TgIcon from './svg-react/Tg'
import TfIcon from './svg-react/Tf'
import TdIcon from './svg-react/Td'
import TaIcon from './svg-react/Ta'
import SzIcon from './svg-react/Sz'
import SyIcon from './svg-react/Sy'
import SxIcon from './svg-react/Sx'
import StIcon from './svg-react/St'
import SsIcon from './svg-react/Ss'
import SrIcon from './svg-react/Sr'
import SoIcon from './svg-react/So'
import SnIcon from './svg-react/Sn'
import SmIcon from './svg-react/Sm'
import SlIcon from './svg-react/Sl'
import SkIcon from './svg-react/Sk'
import SjIcon from './svg-react/Sj'
import SiIcon from './svg-react/Si'
import ShIcon from './svg-react/Sh'
import SgIcon from './svg-react/Sg'
import SeIcon from './svg-react/Se'
import SdIcon from './svg-react/Sd'
import ScIcon from './svg-react/Sc'
import SbIcon from './svg-react/Sb'
import SaIcon from './svg-react/Sa'
import RuIcon from './svg-react/Ru'
import RoIcon from './svg-react/Ro'
import ReIcon from './svg-react/Re'
import QaIcon from './svg-react/Qa'
import PyIcon from './svg-react/Py'
import PwIcon from './svg-react/Pw'
import PsIcon from './svg-react/Ps'
import PrIcon from './svg-react/Pr'
import PmIcon from './svg-react/Pm'
import PlIcon from './svg-react/Pl'
import PkIcon from './svg-react/Pk'
import PhIcon from './svg-react/Ph'
import PgIcon from './svg-react/Pg'
import PeIcon from './svg-react/Pe'
import PaIcon from './svg-react/Pa'
import OmIcon from './svg-react/Om'
import NzIcon from './svg-react/Nz'
import NuIcon from './svg-react/Nu'
import NrIcon from './svg-react/Nr'
import NpIcon from './svg-react/Np'
import NoIcon from './svg-react/No'
import NlIcon from './svg-react/Nl'
import NgIcon from './svg-react/Ng'
import NeIcon from './svg-react/Ne'
import NcIcon from './svg-react/Nc'
import NaIcon from './svg-react/Na'
import MzIcon from './svg-react/Mz'
import MyIcon from './svg-react/My'
import MxIcon from './svg-react/Mx'
import MwIcon from './svg-react/Mw'
import MvIcon from './svg-react/Mv'
import MuIcon from './svg-react/Mu'
import MtIcon from './svg-react/Mt'
import MsIcon from './svg-react/Ms'
import MrIcon from './svg-react/Mr'
import MqIcon from './svg-react/Mq'
import MoIcon from './svg-react/Mo'
import MnIcon from './svg-react/Mn'
import MmIcon from './svg-react/Mm'
import MlIcon from './svg-react/Ml'
import MkIcon from './svg-react/Mk'
import MhIcon from './svg-react/Mh'
import MgIcon from './svg-react/Mg'
import MfIcon from './svg-react/Mf'
import MeIcon from './svg-react/Me'
import McIcon from './svg-react/Mc'
import MaIcon from './svg-react/Ma'
import LyIcon from './svg-react/Ly'
import LvIcon from './svg-react/Lv'
import LuIcon from './svg-react/Lu'
import LtIcon from './svg-react/Lt'
import LsIcon from './svg-react/Ls'
import LrIcon from './svg-react/Lr'
import LcIcon from './svg-react/Lc'
import LbIcon from './svg-react/Lb'
import LaIcon from './svg-react/La'
import KzIcon from './svg-react/Kz'
import KyIcon from './svg-react/Ky'
import KwIcon from './svg-react/Kw'
import KpIcon from './svg-react/Kp'
import KnIcon from './svg-react/Kn'
import KmIcon from './svg-react/Km'
import KiIcon from './svg-react/Ki'
import KgIcon from './svg-react/Kg'
import KeIcon from './svg-react/Ke'
import JpIcon from './svg-react/Jp'
import JoIcon from './svg-react/Jo'
import JmIcon from './svg-react/Jm'
import JeIcon from './svg-react/Je'
import ItIcon from './svg-react/It'
import IsIcon from './svg-react/Is'
import IrIcon from './svg-react/Ir'
import IqIcon from './svg-react/Iq'
import ImIcon from './svg-react/Im'
import IlIcon from './svg-react/Il'
import IeIcon from './svg-react/Ie'
import IdIcon from './svg-react/Id'
import IcIcon from './svg-react/Ic'
import HuIcon from './svg-react/Hu'
import HrIcon from './svg-react/Hr'
import HnIcon from './svg-react/Hn'
import HmIcon from './svg-react/Hm'
import HkIcon from './svg-react/Hk'
import GyIcon from './svg-react/Gy'
import GwIcon from './svg-react/Gw'
import GuIcon from './svg-react/Gu'
import GtIcon from './svg-react/Gt'
import GrIcon from './svg-react/Gr'
import GqIcon from './svg-react/Gq'
import GpIcon from './svg-react/Gp'
import GnIcon from './svg-react/Gn'
import GmIcon from './svg-react/Gm'
import GlIcon from './svg-react/Gl'
import GiIcon from './svg-react/Gi'
import GhIcon from './svg-react/Gh'
import GgIcon from './svg-react/Gg'
import GfIcon from './svg-react/Gf'
import GeIcon from './svg-react/Ge'
import GbSctIcon from './svg-react/GbSct'
import GbEngIcon from './svg-react/GbEng'
import GaIcon from './svg-react/Ga'
import FrIcon from './svg-react/Fr'
import FoIcon from './svg-react/Fo'
import FmIcon from './svg-react/Fm'
import FkIcon from './svg-react/Fk'
import FiIcon from './svg-react/Fi'
import EuIcon from './svg-react/Eu'
import EtIcon from './svg-react/Et'
import EsCtIcon from './svg-react/EsCt'
import ErIcon from './svg-react/Er'
import EhIcon from './svg-react/Eh'
import EgIcon from './svg-react/Eg'
import EeIcon from './svg-react/Ee'
import EcIcon from './svg-react/Ec'
import DzIcon from './svg-react/Dz'
import DoIcon from './svg-react/Do'
import DmIcon from './svg-react/Dm'
import DkIcon from './svg-react/Dk'
import DjIcon from './svg-react/Dj'
import CzIcon from './svg-react/Cz'
import CyIcon from './svg-react/Cy'
import CwIcon from './svg-react/Cw'
import CvIcon from './svg-react/Cv'
import CuIcon from './svg-react/Cu'
import CrIcon from './svg-react/Cr'
import CpIcon from './svg-react/Cp'
import CoIcon from './svg-react/Co'
import CnIcon from './svg-react/Cn'
import ClIcon from './svg-react/Cl'
import CkIcon from './svg-react/Ck'
import CiIcon from './svg-react/Ci'
import ChIcon from './svg-react/Ch'
import CgIcon from './svg-react/Cg'
import CfIcon from './svg-react/Cf'
import CeftaIcon from './svg-react/Cefta'
import CdIcon from './svg-react/Cd'
import CcIcon from './svg-react/Cc'
import CaIcon from './svg-react/Ca'
import ByIcon from './svg-react/By'
import BwIcon from './svg-react/Bw'
import BvIcon from './svg-react/Bv'
import BsIcon from './svg-react/Bs'
import BrIcon from './svg-react/Br'
import BqIcon from './svg-react/Bq'
import BnIcon from './svg-react/Bn'
import BlIcon from './svg-react/Bl'
import BjIcon from './svg-react/Bj'
import BiIcon from './svg-react/Bi'
import BhIcon from './svg-react/Bh'
import BgIcon from './svg-react/Bg'
import BfIcon from './svg-react/Bf'
import BeIcon from './svg-react/Be'
import BdIcon from './svg-react/Bd'
import BbIcon from './svg-react/Bb'
import BaIcon from './svg-react/Ba'
import AzIcon from './svg-react/Az'
import AxIcon from './svg-react/Ax'
import AwIcon from './svg-react/Aw'
import AuIcon from './svg-react/Au'
import AtIcon from './svg-react/At'
import AsIcon from './svg-react/As'
import AqIcon from './svg-react/Aq'
import AoIcon from './svg-react/Ao'
import AmIcon from './svg-react/Am'
import AlIcon from './svg-react/Al'
import AiIcon from './svg-react/Ai'
import AgIcon from './svg-react/Ag'
import AfIcon from './svg-react/Af'
import AeIcon from './svg-react/Ae'
import AdIcon from './svg-react/Ad'
import AcIcon from './svg-react/Ac'
import GbIcon from './svg-react/Gb'
import DeIcon from './svg-react/De'

const iconsMap = {
  'ea': EaIcon,
  'bm': BmIcon,
  'zm': ZmIcon,
  'vi': ViIcon,
  'va': VaIcon,
  'uz': UzIcon,
  'uy': UyIcon,
  'tj': TjIcon,
  'sv': SvIcon,
  'tc': TcIcon,
  'rw': RwIcon,
  'rs': RsIcon,
  'pt': PtIcon,
  'pn': PnIcon,
  'pf': PfIcon,
  'ni': NiIcon,
  'nf': NfIcon,
  'mp': MpIcon,
  'md': MdIcon,
  'lk': LkIcon,
  'li': LiIcon,
  'kr': KrIcon,
  'kh': KhIcon,
  'io': IoIcon,
  'in': InIcon,
  'ht': HtIcon,
  'gs': GsIcon,
  'gd': GdIcon,
  'gbwls': GbWlsIcon,
  'gbnir': GbNirIcon,
  'fj': FjIcon,
  'esga': EsGaIcon,
  'es': EsIcon,
  'dg': DgIcon,
  'cx': CxIcon,
  'cm': CmIcon,
  'bz': BzIcon,
  'bt': BtIcon,
  'bo': BoIcon,
  'ar': ArIcon,
  'zw': ZwIcon,
  'za': ZaIcon,
  'yt': YtIcon,
  'ye': YeIcon,
  'xx': XxIcon,
  'xk': XkIcon,
  'ws': WsIcon,
  'wf': WfIcon,
  'vu': VuIcon,
  'vn': VnIcon,
  'vg': VgIcon,
  've': VeIcon,
  'vc': VcIcon,
  'us': UsIcon,
  'un': UnIcon,
  'um': UmIcon,
  'ug': UgIcon,
  'ua': UaIcon,
  'tz': TzIcon,
  'tw': TwIcon,
  'tv': TvIcon,
  'tt': TtIcon,
  'tr': TrIcon,
  'to': ToIcon,
  'tn': TnIcon,
  'tm': TmIcon,
  'tl': TlIcon,
  'tk': TkIcon,
  'th': ThIcon,
  'tg': TgIcon,
  'tf': TfIcon,
  'td': TdIcon,
  'ta': TaIcon,
  'sz': SzIcon,
  'sy': SyIcon,
  'sx': SxIcon,
  'st': StIcon,
  'ss': SsIcon,
  'sr': SrIcon,
  'so': SoIcon,
  'sn': SnIcon,
  'sm': SmIcon,
  'sl': SlIcon,
  'sk': SkIcon,
  'sj': SjIcon,
  'si': SiIcon,
  'sh': ShIcon,
  'sg': SgIcon,
  'se': SeIcon,
  'sd': SdIcon,
  'sc': ScIcon,
  'sb': SbIcon,
  'sa': SaIcon,
  'ru': RuIcon,
  'ro': RoIcon,
  're': ReIcon,
  'qa': QaIcon,
  'py': PyIcon,
  'pw': PwIcon,
  'ps': PsIcon,
  'pr': PrIcon,
  'pm': PmIcon,
  'pl': PlIcon,
  'pk': PkIcon,
  'ph': PhIcon,
  'pg': PgIcon,
  'pe': PeIcon,
  'pa': PaIcon,
  'om': OmIcon,
  'nz': NzIcon,
  'nu': NuIcon,
  'nr': NrIcon,
  'np': NpIcon,
  'no': NoIcon,
  'nl': NlIcon,
  'ng': NgIcon,
  'ne': NeIcon,
  'nc': NcIcon,
  'na': NaIcon,
  'mz': MzIcon,
  'my': MyIcon,
  'mx': MxIcon,
  'mw': MwIcon,
  'mv': MvIcon,
  'mu': MuIcon,
  'mt': MtIcon,
  'ms': MsIcon,
  'mr': MrIcon,
  'mq': MqIcon,
  'mo': MoIcon,
  'mn': MnIcon,
  'mm': MmIcon,
  'ml': MlIcon,
  'mk': MkIcon,
  'mh': MhIcon,
  'mg': MgIcon,
  'mf': MfIcon,
  'me': MeIcon,
  'mc': McIcon,
  'ma': MaIcon,
  'ly': LyIcon,
  'lv': LvIcon,
  'lu': LuIcon,
  'lt': LtIcon,
  'ls': LsIcon,
  'lr': LrIcon,
  'lc': LcIcon,
  'lb': LbIcon,
  'la': LaIcon,
  'kz': KzIcon,
  'ky': KyIcon,
  'kw': KwIcon,
  'kp': KpIcon,
  'kn': KnIcon,
  'km': KmIcon,
  'ki': KiIcon,
  'kg': KgIcon,
  'ke': KeIcon,
  'jp': JpIcon,
  'jo': JoIcon,
  'jm': JmIcon,
  'je': JeIcon,
  'it': ItIcon,
  'is': IsIcon,
  'ir': IrIcon,
  'iq': IqIcon,
  'im': ImIcon,
  'il': IlIcon,
  'ie': IeIcon,
  'id': IdIcon,
  'ic': IcIcon,
  'hu': HuIcon,
  'hr': HrIcon,
  'hn': HnIcon,
  'hm': HmIcon,
  'hk': HkIcon,
  'gy': GyIcon,
  'gw': GwIcon,
  'gu': GuIcon,
  'gt': GtIcon,
  'gr': GrIcon,
  'gq': GqIcon,
  'gp': GpIcon,
  'gn': GnIcon,
  'gm': GmIcon,
  'gl': GlIcon,
  'gi': GiIcon,
  'gh': GhIcon,
  'gg': GgIcon,
  'gf': GfIcon,
  'ge': GeIcon,
  'gbsct': GbSctIcon,
  'gbeng': GbEngIcon,
  'ga': GaIcon,
  'fr': FrIcon,
  'fo': FoIcon,
  'fm': FmIcon,
  'fk': FkIcon,
  'fi': FiIcon,
  'eu': EuIcon,
  'et': EtIcon,
  'esct': EsCtIcon,
  'er': ErIcon,
  'eh': EhIcon,
  'eg': EgIcon,
  'ee': EeIcon,
  'ec': EcIcon,
  'dz': DzIcon,
  'do': DoIcon,
  'dm': DmIcon,
  'dk': DkIcon,
  'dj': DjIcon,
  'cz': CzIcon,
  'cy': CyIcon,
  'cw': CwIcon,
  'cv': CvIcon,
  'cu': CuIcon,
  'cr': CrIcon,
  'cp': CpIcon,
  'co': CoIcon,
  'cn': CnIcon,
  'cl': ClIcon,
  'ck': CkIcon,
  'ci': CiIcon,
  'ch': ChIcon,
  'cg': CgIcon,
  'cf': CfIcon,
  'cefta': CeftaIcon,
  'cd': CdIcon,
  'cc': CcIcon,
  'ca': CaIcon,
  'by': ByIcon,
  'bw': BwIcon,
  'bv': BvIcon,
  'bs': BsIcon,
  'br': BrIcon,
  'bq': BqIcon,
  'bn': BnIcon,
  'bl': BlIcon,
  'bj': BjIcon,
  'bi': BiIcon,
  'bh': BhIcon,
  'bg': BgIcon,
  'bf': BfIcon,
  'be': BeIcon,
  'bd': BdIcon,
  'bb': BbIcon,
  'ba': BaIcon,
  'az': AzIcon,
  'ax': AxIcon,
  'aw': AwIcon,
  'au': AuIcon,
  'at': AtIcon,
  'as': AsIcon,
  'aq': AqIcon,
  'ao': AoIcon,
  'am': AmIcon,
  'al': AlIcon,
  'ai': AiIcon,
  'ag': AgIcon,
  'af': AfIcon,
  'ae': AeIcon,
  'ad': AdIcon,
  'ac': AcIcon,
  'gb': GbIcon,
  'de': DeIcon,
}

export default iconsMap

import type { SquareButtonProps } from '../Types'
import { cloneElement, useMemo, memo } from 'react'
import classNames from 'classnames'
import isPresent from '@vayapin/utils/isPresent'

export interface SquareProps {
  icon: SquareButtonProps['icon'];
  disabled?: SquareButtonProps['disabled'];
  blurred?: SquareButtonProps['iconBlurred'];
  className?: string;
  disabledClassName?: string;
  iconClassName?: string;
  iconDisabledClassName?: string;
}

const BOX_BASE_CLASSES = `
  vp-square
  relative overflow-hidden h-10 w-10 rounded-lg border-2
  flex items-center justify-center grow-0 shrink-0
`

function SquareBase({
  icon,
  disabled,
  blurred,
  className = '',
  disabledClassName = '',
  iconClassName = '',
  iconDisabledClassName = '',
}: Readonly<SquareProps>) {
  const boxClasses = useMemo(() => classNames({
    [BOX_BASE_CLASSES]: true,
    [className]: !disabled && isPresent(className),
    [`${disabledClassName} disabled`]: disabled && isPresent(disabledClassName),
  }), [className, disabled, disabledClassName])

  const iconClasses = useMemo(() => classNames({
    ['w-5 h-5']: true,
    ['blur-sm']: blurred,
    [iconClassName]: !disabled && isPresent(iconClassName),
    [`${iconDisabledClassName} disabled`]: disabled && isPresent(iconDisabledClassName),
  }), [disabled, blurred, iconClassName, iconDisabledClassName])

  const adjustedIcon = useMemo(() => icon ? cloneElement(icon, {
    className: iconClasses,
  }) : undefined, [icon, iconClasses])

  return (
    <div className={boxClasses}>
      {adjustedIcon}
    </div>
  )
}

export function SquareFactory({
  displayName,
  className,
  disabledClassName,
  iconClassName,
  iconDisabledClassName,
}: {
  displayName: string,
  className?: string,
  disabledClassName?: string,
  iconClassName?: string,
  iconDisabledClassName?: string,
}) {
  const Comp = memo((props: SquareProps) => {
    const cls = useMemo(() => classNames({
      [className ?? '']: isPresent(className),
      [props.className ?? '']: isPresent(props.className),
    }), [props.className])

    const disabledCls = useMemo(() => classNames({
      [disabledClassName ?? '']: isPresent(disabledClassName),
      [props.disabledClassName ?? '']: isPresent(props.disabledClassName),
    }), [props.disabledClassName])

    const iconCls = useMemo(() => classNames({
      [iconClassName ?? '']: isPresent(iconClassName),
      [props.iconClassName ?? '']: isPresent(props.iconClassName),
    }), [props.iconClassName])

    const iconDisabledCls = useMemo(() => classNames({
      [iconDisabledClassName ?? '']: isPresent(iconDisabledClassName),
      [props.iconDisabledClassName ?? '']: isPresent(props.iconDisabledClassName),
    }), [props.iconDisabledClassName])

    return (
      <SquareBase
        {...props}
        className={cls}
        disabledClassName={disabledCls}
        iconClassName={iconCls}
        iconDisabledClassName={iconDisabledCls}
      />
    )
  })
  Comp.displayName = displayName // required for debugging
  return Comp
}

const Square = {
  Default: SquareFactory({
    displayName: 'DefaultSquare',
    className: 'bg-white border-gray-300',
    disabledClassName: '',
    iconClassName: 'text-neutral-800 hover:text-primary-400',
    iconDisabledClassName: 'text-neutral-400',
  }),
  Primary: SquareFactory({
    displayName: 'DefaultSquare',
    className: `
      bg-primary-400 border-primary-400
      hover:bg-primary-300 hover:border-primary-300
    `,
    disabledClassName: `
      bg-primary-300 border-primary-300
      hover:bg-primary-300 hover:border-primary-300
    `,
    iconClassName: 'text-white',
    iconDisabledClassName: 'text-white',
  }),
  Secondary: SquareFactory({
    displayName: 'DefaultSquare',
    className: `
      bg-lavender-400 border-lavender-400
      hover:bg-lavender-300 hover:border-lavender-300
    `,
    disabledClassName: `
      bg-lavender-300 border-lavender-300
      hover:bg-lavender-300 hover:border-lavender-300
    `,
    iconClassName: 'text-white',
    iconDisabledClassName: 'text-white',
  }),
}
export default Square

import type { CountrySelectorButtonProps } from '../Types'
import { useMemo } from 'react'
import classNames from 'classnames'
import isPresent from '@vayapin/utils/isPresent'
import ChevronDown from '@vayapin/svg-icons/svg-react/ChevronDown'
import Globe from '@vayapin/svg-icons/svg-react/Globe'
import CountryFlag from '../../CountryFlag'
import FlatButton from '../FlatButton'

function CountrySelectorButton({
  countryCode,
  title,
  placeholder,
  ...restProps
}: Readonly<CountrySelectorButtonProps>) {
  const isEmpty = useMemo(() => !isPresent(countryCode), [countryCode])
  const showTitle = useMemo(() => isPresent(title) && !isEmpty, [isEmpty, title])

  const iconClasses = useMemo(() => classNames({
    'opacity-40': isEmpty,
  }), [isEmpty])

  return (
    <FlatButton {...restProps} className='justify-between'>
      <CountryFlag countryCode={countryCode} fallbackIcon={<Globe />} className={iconClasses} />
      {isEmpty && isPresent(placeholder) && <span className="mx-2 grow shrink opacity-40">
        {placeholder}
      </span>}
      {showTitle && <span className="mx-2 grow shrink">{title}</span>}
      <ChevronDown className="h-6 w-6" />
    </FlatButton>
  )
}

export default CountrySelectorButton

import CircleButton from '../CircleButton'
import type { CircleButtonProps } from '../Types'

function FloatingCircleButton(props: CircleButtonProps) {
  return (
    <div className="fixed z-10 flex items-center justify-center right-0 bottom-0 mx-6 my-4">
      <CircleButton {...props} />
    </div>
  )
}

export default FloatingCircleButton

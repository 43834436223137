import type { DarkCircleButtonProps } from '../Types'
import { cloneElement, useMemo } from 'react'
import classNames from 'classnames'
import isPresent from '@vayapin/utils/isPresent'
import TimesIcon from '@vayapin/svg-icons/svg-react/Times'
import TypedButtonWrapper from '../TypedButtonWrapper'

export interface DarkCircleButtonIconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

const DEFAULT_CLASSES = `
  relative w-8 h-8 rounded-full
  flex items-center justify-center
`

const NOT_DISABLED_CLASSES = `
  bg-neutral-800 hover:bg-neutral-700
`

const DISABLED_CLASSES = `
  bg-neutral-400
`

function DarkCircleButton({
  icon = <TimesIcon />,
  disabled = false,
  className,
  href,
  type,
  ...restProps
}: Readonly<DarkCircleButtonProps>) {
  const secureType = useMemo(() => isPresent(href) ? 'link' : type, [type, href])

  const closeClasses = useMemo(() => classNames({
    [DEFAULT_CLASSES]: true,
    [NOT_DISABLED_CLASSES]: !disabled,
    [DISABLED_CLASSES]: disabled,
    [className ?? '']: isPresent(className),
  }), [className, disabled])

  const normalizedIcon = useMemo(() => cloneElement(icon, {
    className: 'text-white w-6 h-6',
  }), [icon])

  return (
    <TypedButtonWrapper
      disabled={disabled}
      type={secureType}
      className={closeClasses}
      href={href}
      {...restProps}
    >
      {normalizedIcon}
    </TypedButtonWrapper>
  )
}

export default DarkCircleButton

import type { CircleButtonProps } from '../Types'
import { cloneElement, useMemo } from 'react'
import classNames from 'classnames'

export interface CircleProps {
  disabled: CircleButtonProps['disabled'];
  icon: CircleButtonProps['icon'];
  theme: CircleButtonProps['theme'];
}

const DEFAULT_CLASSES = `
  relative overflow-hidden rounded-full h-16 w-16
  flex items-center justify-center grow-0 shrink-0
`

const NOT_DISABLED_CLASSES = `
  bg-vp-gradient bg-vp-gradient-start
  group-hover/vp-circle-btn:bg-vp-gradient-light group-hover/vp-circle-btn:bg-vp-gradient-light-start
  shadow-xl group-active/vp-circle-btn:shadow-lg
`

const DISABLED_CLASSES = `
  opacity-60 shadow-none group-active/vp-circle-btn:shadow-none
  bg-vp-gradient-light bg-vp-gradient-light-start
`

const PRIM_NOT_DISABLED_CLASSES = `
  bg-primary-500 group-hover/vp-circle-btn:bg-primary-400
  shadow-xl group-active/vp-circle-btn:shadow-lg
`

const PRIM_DISABLED_CLASSES = `
  bg-primary-500 opacity-60 shadow-none group-active/vp-circle-btn:shadow-none
`

const SEC_NOT_DISABLED_CLASSES = `
  bg-secondary-600 group-hover/vp-circle-btn:bg-secondary-500
  shadow-xl group-active/vp-circle-btn:shadow-lg
`

const SEC_DISABLED_CLASSES = `
  bg-secondary-600 opacity-60 shadow-none group-active/vp-circle-btn:shadow-none
`

const LAVENDER_NOT_DISABLED_CLASSES = `
  bg-lavender-400 group-hover/vp-circle-btn:bg-lavender-300
  shadow-xl group-active/vp-circle-btn:shadow-lg
`

const LAVENDER_DISABLED_CLASSES = `
  bg-lavender-400 opacity-60 shadow-none group-active/vp-circle-btn:shadow-none
`

function Circle({ disabled, icon, theme }: Readonly<CircleProps>) {
  const circleClasses = useMemo(() => classNames({
    [DEFAULT_CLASSES]: true,
    [NOT_DISABLED_CLASSES]: !disabled && theme === 'default',
    [DISABLED_CLASSES]: disabled && theme === 'default',
    [PRIM_NOT_DISABLED_CLASSES]: !disabled && theme === 'primary',
    [PRIM_DISABLED_CLASSES]: disabled && theme === 'primary',
    [SEC_NOT_DISABLED_CLASSES]: !disabled && theme === 'secondary',
    [SEC_DISABLED_CLASSES]: disabled && theme === 'secondary',
    [LAVENDER_NOT_DISABLED_CLASSES]: !disabled && theme === 'lavender',
    [LAVENDER_DISABLED_CLASSES]: disabled && theme === 'lavender',
  }), [disabled, theme])

  const adjustedIcon = useMemo(() => icon ? cloneElement(icon, {
    className: 'text-white w-9 h-9 text-white',
  }) : undefined, [icon])

  return (
    <div className={circleClasses}>
      {adjustedIcon}
    </div>
  )
}

export default Circle

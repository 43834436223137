import type { ButtonProps } from './Types'
import { useMemo } from 'react'
import classNames from 'classnames'
import TypedButtonWrapper from './TypedButtonWrapper'
// @ts-ignore
import Styles from './Button.module.sass'
import isPresent from '@vayapin/utils/isPresent'

const DEFAULT_CLASSES = [
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  Styles.button,
  'inline-block rounded-full h-12',
  'relative border-none overflow-hidden outline-0 pointer-events-auto no-underline',
  'flex flex-row items-center justify-center gap-2',
  'my-2 py-3 px-5',
  'hover:cursor-pointer text-white hover:text-white',
].join(' ')

const NOT_DISABLED_CLASSES = `
  shadow-xl active:shadow-lg
  bg-vp-gradient-start bg-vp-gradient hover:bg-vp-gradient-light-start hover:bg-vp-gradient-light
`

const DISABLED_CLASSES = `
  opacity-60 shadow-none active:shadow-none hover:cursor-not-allowed
  bg-vp-gradient-light-start bg-vp-gradient-light
`

function Button({
  compact = false,
  type = 'default',
  href,
  className,
  disabled,
  ...restProps
}: Readonly<ButtonProps>) {
  const secureType = useMemo(() => isPresent(href) ? 'link' : type, [type, href])

  const classes = useMemo(() => classNames({
    [DEFAULT_CLASSES]: true,
    [NOT_DISABLED_CLASSES]: !disabled,
    [DISABLED_CLASSES]: disabled,
    'min-w-80': compact,
    [className ?? '']: isPresent(className),
  }), [className, compact, disabled])

  return (
    <TypedButtonWrapper
      disabled={disabled}
      type={secureType}
      className={classes}
      href={href}
      {...restProps}
    />
  )
}

export default Button
